/*
 * Bootstrap overrides
 */
$spacer: 1rem;
$enable-flex: false;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: true;

$font-family-sans-serif: 'Roboto', sans-serif !default;


// old vars
$body-text-color: #212121;
$primary-color: #388E3C;
$primary-active-color: #4CAF50;
$navbar-text-color: #DCEDC8;
$container-width: 62rem;