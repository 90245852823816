@import '_color-definitions';
@import '_variables';
$link-color: #4CAF50;
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

//a {
//  color: unquote("rgba(#{$palette-green-A700}, 1)");
//}

body {
  background-color: unquote("rgb(#{$palette-brown-50})");
}

.navbar--no-top-padding {
  padding-top: 0;
}
.navbar--no-bot-padding {
  padding-bottom: 0;

  .navbar-nav .nav-item + .nav-item {
    margin-left: 2rem;
  }

  .nav-item .nav-link {
      padding-top: 1.125rem;
      padding-bottom: 1.125rem;
      border-bottom: 4px solid;
      border-color: rgba(0, 0, 0, 0);
  }

  .nav-item .nav-link.active {
    border-color: unquote("rgba(#{$palette-green-A700}, 1)");
  }
}
.content-spacing > h1,h2,h3,h4,h5,h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.container {
  width: auto;
  max-width: $container-width;
  padding: 0 1rem;
}

.navbar-toggler.hidden-sm-up {
  color: #FFFFFF;
  opacity: .65
}

@media(max-width: 33.99em) {
  .ribbon {
    padding-top: $spacer;
    padding-bottom: $spacer;
  }
  .ribbon .container > h1 {
    font-size: 2rem;
  }
  .container .content {
    padding-top: $spacer;
    margin: 0 0;
  }
  .content-spacing {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media(min-width: 34em) {
  .ribbon {
    padding: $spacer*2;
    padding-top: 2.375rem;
    padding-bottom: 2.375rem;
  }
  .container .content {
    margin: 0 0;
    padding: 2rem;
    font-size: 1.2rem;
  }
}
.wrapper {
  //background-color: unquote("rgb(#{$palette-brown-700})");
  width: 100%;
  padding: 1.5rem 0 1.5rem;
}

.ribbon {
  width: 100%;
  background-color: unquote("rgb(#{$palette-brown-300})");
}
.navbar-primary-bg-color {
  background-color: unquote("rgb(#{$palette-brown-700})");
}

.container.nav-top img.logo {
  width: 5rem;
}

@media (max-width: 33.99em) {
  .container.nav-top img.logo {
    width: 4em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.container.nav-top img.logo-text {
  height: 3.5rem;
  margin-top: 0;
}

@media (max-width: 33.99em) {
  .container.nav-top img.logo-text {
    height: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 34em) {
  .container.nav-top img.logo-text {
    margin-top: 1rem;
    height: 2.5rem;
  }
}

@media (min-width: 48em) {
  .container.nav-top img.logo-text {
    margin-top: 1rem;
    height: 3rem;
  }
}

@media (min-width: 62em) {
  .container.nav-top img.logo-text {
    margin-top: 1rem;
    height: 3.5rem;
  }
}

.container.nav-top div.top-logo {
  float: left;
}

@media (max-width: 33.99em) {
  .container.nav-top div.top-logo {
    width: 100%;
  }
}

.container.nav-top div.top-logo-text {
  float: left;
}

@media (max-width: 33.99em) {
  .container.nav-top div.top-logo-text {
    width: 100%;
  }
}

.container.nav-top div.contact-us {
  float: right;
}

@media (max-width: 48em) {
  .container.nav-top div.contact-us {
    clear: both;
    width: 100%;
    text-align: center;
  }
}

footer {
  margin-top: 2rem;
}

div.content {
  margin-top: 2rem;
}

.footer {
  background-color: unquote("rgb(#{$palette-brown-700})");
  padding-top: 33px;
  padding-bottom: 33px;
}

@media (max-width: 33.99em) {
  .branches div:not(:first-child) {
    margin-top: 10px;
  }
}

.header-spacer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.casts-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
